import React from 'react'

import LandingLayout from '@layouts/Landing'
import { Container, H1, H2 as H2Default } from '@layouts/Landing/components'
import { styled } from '@util/tw'
import Link from '@components/Link'
import SEO from '@components/seo'

const H2 = styled(H2Default, 'mt-8')

const P = styled('p', 'mb-2')
const UL = styled('ul', 'list-disc list-inside')

export default () => {
  return (
    <LandingLayout>
      <SEO
        title="Terms of Service"
        description="Sentimentor Terms of Service"
      />
      <Container className="flex-col">
        <H1 style={{ color: 'black' }}>Terms of Service</H1>
        <p>Last revised on 2020-07-01</p>

        <H2>The Gist</H2>

        <P>
          Mad Filaments operates the Sentimentor service, which we hope you use.
          If you use it, please use it responsibly. If you don't, we'll have to
          terminate your account.
        </P>

        <P>
          For paid accounts, you'll be charged on a monthly or annual basis. You
          can cancel anytime, but there are no refunds.
        </P>

        <P>
          The Terms of Service, Sentimentor itself, and our prices can change at
          any time. We'll warn you 30 days in advance of any price changes.
          We'll try to warn you about major changes to the Terms of Service or
          Sentimentor, but we make no guarantees.
        </P>

        <P>
          That's the basic idea, but You must read through the entire Terms of
          Service below and agree with all the details before You use any of our
          sites (whether or not you have created an account).
        </P>

        <H2>Reuse</H2>

        <P>
          This document is an adaptation of the{' '}
          <Link to="http://legal.heroku.com/tos" external>
            Heroku Terms of Service
          </Link>
          , which is turn an adaptation of the{' '}
          <Link to="http://code.google.com/appengine/terms.html" external>
            Google App Engine Terms of Service
          </Link>
          . The original work has been modified with permission under the{' '}
          <Link to="http://creativecommons.org/licenses/by/3.0/" external>
            Creative Commons Attribution 3.0 License
          </Link>
          . Neither Heroku, Inc. nor Google, Inc. is not connected with and does
          not sponsor or endorse Mad Filaments or its use of the work.
        </P>

        <P>
          You're welcome to adapt and use this document for your own needs. If
          you make an improvement, we'd appreciate it if you would let us know
          so we can consider improving our own document.
        </P>

        <H2>Your Agreement with Mad Filaments</H2>

        <P>
          Your use of the Mad Filaments service is governed by this agreement
          (the "Terms"). "Mad Filaments" means Mad Filaments Inc. The "Service"
          means the services Mad Filaments makes available include our web sites
          (
          <Link to="https://sentimentor.co" external>
            https://sentimentor.co
          </Link>
          ), our blog, our API, and any other software, sites, and services
          offered by Mad Filaments in connection to any of those. "Content"
          means all content generated by Mad Filaments on your behalf. "Customer
          Content" means all content that You supply to Mad Filaments for
          processing.
        </P>
        <P>
          In order to use the Service, You (the "Customer", "You", or "Your")
          must first agree to the Terms. You understand and agree that Mad
          Filaments will treat Your use of the Service as acceptance of the
          Terms from that point onwards.
        </P>
        <P>
          Mad Filaments may make changes to the Terms from time to time. You may
          reject the changes by terminating Your account. You understand and
          agree that if You use the Service after the date on which the Terms
          have changed, Mad Filaments will treat Your use as acceptance of the
          updated Terms.
        </P>
        <P>
          If you have any question about the Terms, please contact us at{' '}
          <Link to="mailto:contact@sentimentor.co" external>
            contact@sentimentor.co
          </Link>
          .
        </P>

        <H2>Your Account</H2>
        <UL>
          <li>
            You may not use the Service if You are a person barred from
            receiving the Service under the laws of the United States or other
            countries, including the country in which You are resident or from
            which You use the Service.
          </li>
          <li>
            You may not use the service unless you are over the age of 13.
          </li>
          <li>
            You must be a human. Account created by automated methods are not
            permitted.
          </li>
        </UL>

        <H2>Use of the Service</H2>
        <UL>
          <li>
            You must provide accurate and complete registration information any
            time You register to use the Service.
          </li>
          <li>
            You are responsible for the security of Your passwords and for any
            use of Your account.
          </li>
          <li>
            Your use of the Service must comply with all applicable laws,
            regulations and ordinances.
          </li>
          <li>
            You agree to not engage in any activity that interferes with or
            disrupts the Service.
          </li>
          <li>
            Mad Filaments reserves the right to enforce quotas and usage limits
            (to any resources, including the API) at its sole discretion, with
            or without notice, which may result in Mad Filaments disabling or
            throttling your usage of the Service for any amount of time.
          </li>
          <li>
            You may not allow multiple people to use the same account or
            otherwise access the Service in a manner intended to avoid incurring
            fees.
          </li>
        </UL>

        <H2>Service Policies and Privacy</H2>
        <P>
          The Service shall be subject to the privacy policy for the Service
          available at{' '}
          <Link to="/legal/privacy" external>
            privacy policy
          </Link>
          . You agree to the use of Your data in accordance with Mad Filaments's
          privacy policies.
        </P>

        <H2>Fees for Use of the Service</H2>
        <UL>
          <li>
            The Service may be provided to You without charge up with certain
            limits or for a certain "trial" period of time.
          </li>
          <li>
            Usage over this limit (or after the "trial" period) or requires Your
            purchase of additional resources or services.
          </li>
          <li>
            For all purchased resources and services, we will charge Your credit
            card on a monthly or annual basis.
          </li>
          <li>
            Payments are non-refundable. There will be no refunds for partial
            months of service, upgrade/downgrade refunds, or refunds for months
            unused with an open account.
          </li>
          <li>
            Charges are solely based on Mad Filaments's measurements of Your use
            of the Service, unless otherwise agreed to in writing.
          </li>
          <li>
            All fees are exclusive of all taxes, levies, or duties imposed by
            taxing authorities, and You shall be responsible for payment of all
            such taxes, levies, or duties.
          </li>
          <li>
            You acknowledge and agree that any credit card and related billing
            and payment information that You provide to Mad Filaments may be
            shared by Mad Filaments with companies who work on Mad Filaments'
            behalf, such as payment processors and/or credit agencies, solely
            for the purposes of checking credit, effecting payment to Mad
            Filaments and servicing Your account.
          </li>
          <li>
            Mad Filaments may change its fees and payment policies for the
            Service by notifying You at least thirty (30) days before the
            beginning of the billing cycle in which such change will take
            effect.
          </li>
        </UL>

        <H2>Cancellation and Termination</H2>
        <UL>
          <li>You will not receive any refunds if You cancel Your account.</li>
          <li>
            If You cancel the Service before the end of Your current paid up
            month, Your cancellation will take effect immediately and You will
            not be charged again.
          </li>
          <li>
            All of Customer Content will, within a reasonable amount of time to
            be determined solely by Mad Filaments, be deleted from the Service
            upon cancellation.
          </li>
          <li>
            You agree that Mad Filaments, in its sole discretion and for any or
            no reason, may terminate or suspend Your account. You agree that any
            termination of Your access to the Service may be without prior
            notice, and You agree that Mad Filaments will not be liable to You
            or any third party for such termination.
          </li>
        </UL>

        <H2>Customer Content</H2>
        <UL>
          <li>
            Mad Filaments claims no ownership or control over any Customer
            Content. You retain copyright and any other rights You already hold
            in the Customer Content and You are responsible for protecting those
            rights, as appropriate.
          </li>
          <li>
            You retain sole responsibility for any collaborators or third-party
            services that you allow to view Customer Content and entrust them at
            your own risk.
          </li>
          <li>
            Mad Filaments is not responsible if you fail to configure, or
            misconfigure, your project and inadvertently allow unauthorized
            parties view any Customer Content.
          </li>
        </UL>

        <H2>Ideas and Feedback</H2>
        <P>
          You may choose to or we may invite You to submit comments or ideas
          about the Service, including but not limited to ideas about improving
          the Service or our products ("Ideas"). By submitting any Idea, You
          agree that Your disclosure is unsolicited and without restriction and
          will not place Mad Filaments under any fiduciary or other obligation,
          and that we are free to use the Idea without any additional
          compensation to You, and/or to disclose the Idea on a non-confidential
          basis or otherwise to anyone.
        </P>

        <H2>Modification of the Service</H2>
        <UL>
          <li>
            You acknowledge and agree that the Service may change from time to
            time without prior notice to You.
          </li>
          <li>
            Changes include, without limitation, changes to fee and payment
            policies, security patches, added or removed functionality, and
            other enhancements or restrictions.
          </li>
          <li>
            Mad Filaments shall not be liable to you or to any third party for
            any modification, price change, suspension or discontinuance of the
            Service.
          </li>
        </UL>

        <H2>External Resources</H2>
        <P>
          The Services may include hyperlinks to other web sites or content or
          resources or email content. You acknowledge and agree that Mad
          Filaments is not responsible for the availability of any such external
          sites or resources, and does not endorse any advertising, products or
          other materials on or available from such web sites or resources.
        </P>

        <H2>License from Mad Filaments and Restrictions</H2>
        <P>
          Mad Filaments gives You a personal, worldwide, royalty-free,
          non-assignable and non-exclusive license to use the software provided
          to You by Mad Filaments as part of the Service as provided to You by
          Mad Filaments. This license is for the sole purpose of enabling You to
          use and enjoy the benefit of the Service as provided by Mad Filaments,
          in the manner permitted by the Terms.
        </P>
        <P>
          You may not (and You may not permit anyone else to): (a) copy, modify,
          create a derivative work of, reverse engineer, decompile or otherwise
          attempt to extract the source code of the Service or any part thereof,
          unless this is expressly permitted or required by law, or unless You
          have been specifically told that You may do so by Mad Filaments, in
          writing (e.g., through an open source software license); or (b)
          attempt to disable or circumvent any security mechanisms used by the
          Service.
        </P>
        <P>
          Open source software licenses for components of the Service released
          under an open source license constitute separate written agreements.
          To the limited extent that the open source software licenses expressly
          supersede these Terms, the open source licenses govern Your agreement
          with Mad Filaments for the use of the components of the Service
          released under an open source license.
        </P>

        <H2>Exclusion of warranties</H2>
        <UL>
          <li>
            You expressly understand and agree that your use of the service is
            at your sole risk and that the service is provided "as is" and "as
            available.".
          </li>
          <li>
            You agree that Mad Filaments has no responsibility or liability for
            the deletion or failure to store any Content and other
            communications maintained or transmitted through use of the Service.
            You further acknowledge that You are solely responsible for securing
            and backing up Customer Content.
          </li>
          <li>
            Mad Filaments does not warrant to you that: (a) your use of the
            service will meet your requirements, (b) your use of the service
            will be uninterrupted, timely, secure or free from error, (c) the
            results or data provided by the Service will be accurate, (d) the
            quality of the service will meet your expectations and (e) any
            errors in the Service will be fixed.
          </li>
        </UL>

        <H2>Limitation of liability</H2>
        <P>
          You expressly understand and agree that Mad Filaments, its
          subsidiaries and affiliates, and its licensors shall not be liable to
          you for any direct, indirect, incidental, special consequential or
          exemplary damages which may be incurred by you, however caused and
          under any theory of liability. This shall include, but not be limited
          to, any loss of profit (whether incurred directly or indirectly), any
          loss of goodwill or business reputation, any loss of data suffered,
          cost of procurement of substitute goods or services, or other
          intangible loss (whether or not Mad Filaments has been advised of or
          should have been aware of the possibility of any such losses arising).
        </P>

        <H2>Indemnification</H2>

        <P>
          You agree to hold harmless and indemnify Mad Filaments, and its
          subsidiaries, affiliates, officers, agents, employees, advertisers,
          licensors, suppliers or partners (collectively "Mad Filaments and
          Partners") from and against any third party claim arising from or in
          any way related to (a) Your breach of the Terms, (b) Your use of the
          Service, (c) Your violation of applicable laws, rules or regulations
          in connection with the Service, or (d) Your Customer Content,
          including any liability or expense arising from all claims, losses,
          damages (actual and consequential), suits, judgments, litigation costs
          and attorneys' fees, of every kind and nature. In such a case, Mad
          Filaments will provide You with written notice of such claim, suit or
          action.
        </P>

        <H2>General Legal Terms</H2>

        <P>
          The Terms constitute the whole legal agreement between You and Mad
          Filaments and govern Your use of the Service and completely replace
          any prior agreements between You and Mad Filaments in relation to the
          Service.
        </P>

        <P>
          You agree that if Mad Filaments does not exercise or enforce any legal
          right or remedy which is contained in the Terms (or which Mad
          Filaments has the benefit of under any applicable law), this will not
          be taken to be a formal waiver of Mad Filaments's rights and that
          those rights or remedies will still be available to Mad Filaments.
        </P>

        <P>
          Mad Filaments shall not be liable for failing or delaying performance
          of its obligations resulting from any condition beyond its reasonable
          control, including but not limited to, governmental action, acts of
          terrorism, earthquake, fire, flood or other acts of God, labor
          conditions, power failures, and Internet disturbances.
        </P>
      </Container>
    </LandingLayout>
  )
}
